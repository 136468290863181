import React from "react"
import { Link } from "gatsby"

import Logo from "../svgs/logo.svg"

const Navbar = () => {
    return (
        <nav className="navbar navbar-expand-md bg-white navbar-light shadow-sm rounded" style={{ zIndex: '100' }}>
            <div className="container">
                <Link className="navbar-brand navbar__logo" to="/"><Logo /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navmenu">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navmenu">
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item"><Link className="nav-link" to="/">Home</Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/#about">About</Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/#projects">Projects</Link></li>
                        <li className="nav-item"><Link className="nav-link" to="/#developmentlog">Development Log</Link></li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
