import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTwitter, faInstagram, faFacebook, faReddit, faYoutube } from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
    return (
        <footer className="footer p-5 bg-dark text-white text-center">
            <div className="container">
                <div className="row mb-2 position-relative">
                    <span className="footer__social-icons-container" style={{ fontSize: '24px' }}>
                        <a href="https://twitter.com/CyzerStudio">
                            <FontAwesomeIcon className="footer__social-icons" 
                                role="button"
                                aria-label="Twitter Icon"
                                icon={ faTwitter } 
                            />
                        </a>
                        <a href="https://www.instagram.com/cyzerstudio/">
                            <FontAwesomeIcon className="footer__social-icons" 
                                role="button"
                                aria-label="Instagram Icon"
                                icon={ faInstagram } 
                            />
                        </a>
                        <a href="https://www.facebook.com/Cyzer-Studio-104233447981936">
                            <FontAwesomeIcon className="footer__social-icons" 
                                role="button"
                                aria-label="Facebook Icon"
                                icon={ faFacebook } 
                            />
                        </a>
                        <a href="https://www.reddit.com/user/CyzerStudio/">
                            <FontAwesomeIcon className="footer__social-icons" 
                                role="button"
                                aria-label="Reddit Icon"
                                icon={ faReddit } 
                            />
                        </a>
                        <a href="https://www.youtube.com/channel/UChXWDbbj8pmB2UoDHh8bjaA">
                            <FontAwesomeIcon className="footer__social-icons" 
                                role="button"
                                aria-label="Youtube Icon"
                                icon={ faYoutube } 
                            />
                        </a>
                    </span>
                </div>
                <div className="row"><small>Copyright &copy; { new Date().getFullYear() } Cyzer Studio LLC</small></div>
            </div>
        </footer>
    )
}

export default Footer
